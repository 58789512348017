<template>
  <div class="thing_goods_list">
    <el-card>
      <el-row class="left m-b-10">
        <el-col :span="24">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="addCategory">添加</el-button>
          <el-button type="default" size="mini" @click="openTable">展开/折叠</el-button>
          <el-button type="default" size="mini" icon="el-icon-refresh-right" @click="getList">刷新</el-button>
        </el-col>
      </el-row>

      <el-table
          :data="list"
          ref="thingCategoryRef"
          style="width: 100%; margin-bottom: 20px;"
          row-key="category_id"
          border
          :default-expand-all="isOpen"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column label="分类名称" width="150">
          <template slot-scope="scope">
            {{scope.row.category_name}}
          </template>
        </el-table-column>
        <el-table-column label="是否显示" prop="is_show">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.is_show">是</span>
              <span v-else>否</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort_order"></el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="primary" plain @click="edit(scope.row.category_id)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="remove(scope.row.category_id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        isOpen: true,
        list:[]
      }
    },
    created() {
      if(!this.$store.state.userInfo) {
        // 防止未登录
        this.$router.push({
          path: '/login'
        })
      } else {
        this.getList();
      }
    },
    methods: {
      getList(){
        var url = 'thing/category_list';
        let params = {};
        this.fd_post(url, params).then((res) => {
          console.log(res);
          if(res.status) {
            this.list = res.list;
            console.log(this.list);
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      //折叠
      openTable() {
        this.isOpen = !this.isOpen;
        this.fold(this.list, this.isOpen);
      },
      fold(arr, isOpen) {
        arr.forEach((i) => {
          this.$refs.thingCategoryRef.toggleRowExpansion(i, isOpen);
          if(i.children) {
            this.fold(i.children, isOpen);
          }
        });
      },
      //新增
      addCategory() {
        this.$router.push('/thing_category_add');
      },
      edit(category_id) {
        this.$router.push({path:'/thing_category_add',query: {id: category_id}})
      },
      remove(category_id) {
        this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var url = 'thing/deleteCategory';
          let params = {
            category_id: category_id
          };
          this.fd_post(url, params).then((res) => {
            if(res.status) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          }).catch((err) => {
            this.$message.error('网络错误');
          });
        }).catch(() => {
          this.$message.info('已取消删除');
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  .thing_goods_list{

  }
</style>